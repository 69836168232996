import { useSelector } from 'src/hooks/useSelector';
const LANGUAGE_LEVEL_IDS = {
  a1: '1',
  a2: '2',
  b1: '3',
  b2: '4',
  c1: '5',
  c2: '6',
  l1: '7'
};
export const getLanguageLevel = _ref => {
  let {
    languages,
    record
  } = _ref;
  if (record.length > 0) {
    var _languages$find;
    const [code, degree] = record.split('.');
    const languageId = (_languages$find = languages.find(lang => lang.code === code)) === null || _languages$find === void 0 ? void 0 : _languages$find.id.toString();
    const selectedLevel = degree;
    const levelId = LANGUAGE_LEVEL_IDS[selectedLevel];
    return languageId !== undefined && levelId ? `${languageId}.${levelId}` : null;
  }
  return null;
};
const useConvertLanguageLevel = record => {
  const languages = useSelector(_ref2 => {
    let {
      languages
    } = _ref2;
    return languages;
  });
  return getLanguageLevel({
    languages: languages.language,
    record
  });
};
export default useConvertLanguageLevel;